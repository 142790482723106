import Vue from "vue";
// import inter from "./inter";
import interStatic from "./inter-static.js"
import axios from 'axios'
var $app = new (Vue.extend({
    data() {
        let me = this;
        return {
            inter: interStatic,
            ds: {},
            // 语言
            languages: [{
                name: "CN",
                next: "EN",
                code: "CN",
                nextIndex: 1
            },
            {
                name: "EN",
                next: "CN",
                code: "EN",
                nextIndex: 0
            },
            ],
            currentLanguage: 0,
            // 
            // x:{title:"标题",url:"跳转链接",content:"内容",fileId:"/aaa/a.jpg",fileTip:"图片提示",ext1:"",ext2:""},
            d:{},
            a: {},
        }
    },
    computed: {
        curLan() {
            let me = this;
            return me.languages[me.currentLanguage];
        }
    },
    watch: {
        // 语言变化
        currentLanguage(n) {
            let me = this;
            me.setCookies("ym-offical-language-current", n);
            me.reload();
        }
    },
    methods: {
        getAttr(attr, def) {
            let me = this;
            let arr = attr.split(".");
            if (arr.length < 1 || me.d[arr[0]] === undefined) {
                return def || "";
            }
            return me.d[arr[0]][arr[1]];
        },
        // 存cookies
        setCookies(key, value) {
            if (value instanceof Object) {
                value = JSON.stringify(value);
            }
            localStorage.setItem(key, value);
        },
        // 取cookies
        getCookies(key, def) {
            var x = localStorage.getItem(key) || def;
            if (x && typeof (x) == "string") {
                try {
                    return JSON.parse(x);
                } catch (e) {
                    return x;
                }
            } else {
                return x;
            }
        },
        chageLanguage() {
            let me = this;
            me.currentLanguage = me.curLan.nextIndex;
        },
        reload() {
            let me = this;
            me.load();
            me.loadArticle();
        },
        async load() {
            let me = this;
            try {
                // let arr = (await axios.get("/official-page-web/data/mh-data-type/typeDataTree", {
                //     params: {
                //         prjCode: "ymofficalMobile" + me.curLan.code,
                //         code: "offical"
                //     }
                // })).data.data || [];
                let arr = await me.inter.getMhData({
                    prjCode: "ymoffical" + me.curLan.code,
                    code: "offical"
                });
                let obj = {};
                arr.map(function (it) {
                    if (it.dtype != 0) {
                        obj[it.code] = it.data;
                    }
                });
                me.d = obj;
                document.title = me.getAttr("bottom.title") || "上海嘤鸣企业管理咨询有限公司";
            } catch (error) {
                me.d = {};
                console.log(error)
            }
        },
        async loadArticle() {
            let me = this;
            try {
                // let arr = (await axios.get("/official-page-web/data/mh-article-type/typeDataTree", {
                //     params: {
                //         prjCode: "ymofficalMobile" + me.curLan.code,
                //         code: "officalArticle",
                //         pageIndex: 0,
                //         pageSize: 10000,
                //     }
                // })).data.data || [];
                let arr = await me.inter.getMhArtical({
                    prjCode: "ymoffical" + me.curLan.code,
                    code: "officalArticle",
                    pageIndex: 0,
                    pageSize: 10000,
                })
                let obj = {};
                arr.map(function (it) {
                    obj[it.code] = it.data;
                });
                me.a = obj;
            } catch (error) {
                me.a = {};
                console.log(error)
            }
        }
    },
    created() {
        let me = this;
        let n = me.getCookies("ym-offical-language-current", 0) || 0;
        if (me.currentLanguage != n) {
            me.currentLanguage = n;
        } else {
            me.load();
            me.loadArticle();
        }
    }
}))
Vue.mixin({
    beforeCreate() {
        this.$app = $app;
    },
    methods: {
        $g(attr, def) {
            let me = this;
            return me.$app.getAttr(attr, def);
        }
    },
    computed: {
        $t() {
            return this.$app.d;
        },
        $a() {
            return this.$app.a;
        }
    }
})
export default $app;