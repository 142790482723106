import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home.vue'),
  },
  {
    path: "/new",
    name: "new",
    meta: {
      title: '医讯药闻'
    },
    component: () => import(/* webpackChunkName: "about" */ "@/views/New/index.vue"),
  },
  {
    path: "/new/detail",
    name: "NewDetail",
    meta: {
      title: '医讯药闻'
    },
    component: () => import(/* webpackChunkName: "about" */ "@/views/New/detail.vue"),
  },
  {
    path: "/business",
    name: "business",
    meta: {
      title: '公司业务'
    },
    component: () => import(/* webpackChunkName: "about" */ "@/views/Business"),
  },
  {
    path: `/business/detail/:index`,
    name: "BusinessDetail",
    meta: {
      title: '公司业务'
    },
    component: () => import(/* webpackChunkName: "about" */ "@/views/Business/detail.vue"),
  },
  {
    path: "/aboutus",
    name: "aboutus",
    meta: {
      title: '关于嘤鸣'
    },
    component: () => import(/* webpackChunkName: "about" */ "@/views/Aboutus"),
  },
  {
    path: "/contactus",
    name: "contactus",
    meta: {
      title: '联系我们'
    },
    component: () => import(/* webpackChunkName: "about" */ "@/views/Contactus"),
  },
  {
    path: "/joinus",
    name: "joinus",
    meta: {
      title: '加入我们'
    },
    component: () => import(/* webpackChunkName: "about" */ "@/views/Joinus"),
  },
  {
    path: "/joinus/detail",
    name: "joinusDetail",
    meta: {
      title: '加入我们'
    },
    component: () => import(/* webpackChunkName: "about" */ "@/views/Joinus/detail.vue"),
  },
  { path: '/*', redirect: { name: 'home' }}
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  // mode: 'history',
  routes,
});
router.afterEach((to) => {
  document.title = to.meta.title || '嘤鸣官网'
  document.documentElement.scrollTop = 0;
})
export default router;
