<script>
export default {
  name: "v-swiper",
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    containClass: {
      type: String,
    },
    wrapperClass: {
      type: String,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    showNavigation: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    slidesPerview: {
      type: Number,
      default: 1,
    },
    slidesPergroup: {
      type: Number,
      default: 1,
    },
    slidesPercolumn: {
      type: Number,
      default: 1,
    },
    slidesOffsetbefore: {
      type: Number,
      default: 0,
    },
    slidesOffsetafter: {
      type: Number,
      default: 0,
    },
    slidesPercolumfill: {
      type: String,
      default: "",
    },
    spaceBetween: {
      type: Number,
      default: 0,
    },
    paginationEl: {
      type: Function,
    },
    navigationNextEl: {
      type: Function,
    },
    navigationPrevEl: {
      type: Function,
    },
  },
  render(h) {
    return (
      <div
        ref="swiper"
        class={"por w-p100 ov-h main-swiper " + this.containClass}
      >
        <div class={"txt-c swiper-wrapper " + this.wrapperClass}>
          {this.list.map((item) => {
            return this.$scopedSlots.default({ item });
          })}
        </div>
        {this.renderPagination(h)}
        {this.renderNavigation(h)}
      </div>
    );
  },
  methods: {
    /*eslint no-unused-vars: "off"*/
    renderNavigation(h) {
      if (this.showNavigation) {
        if (this.$slots.navigation) {
          return this.$slots.navigation;
        }
        return (
          <div>
            <div ref="prev" class="swiper-button-prev"></div>
            <div ref="next" class="swiper-button-next"></div>
          </div>
        );
      }
      return;
    },
    /*eslint no-unused-vars: "off"*/
    renderPagination(h) {
      if (this.showPagination) {
        if (this.$slots.pagination) {
          return this.$slots.pagination;
        }
        return <div ref="pagination" class="swiper-pagination"></div>;
      }
      return;
    },
  },
  mounted() {
    /*eslint no-undef: "off"*/
    new Swiper(this.$refs.swiper, {
      loop: this.loop,
      autoplay: this.autoplay,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: this.$slots.navigation
          ? this.navigationNextEl()
          : this.$refs.next,
        prevEl: this.$slots.navigation
          ? this.navigationPrevEl()
          : this.$refs.prev,
      },
      slidesPerView: this.slidesPerview,
      slidesPerGroup: this.slidesPergroup,
      slidesPerColumn: this.slidesPercolumn,
      slidesPerColumnFill: this.slidesPercolumfill,
      slidesOffsetBefore: this.slidesOffsetbefore,
      slidesOffsetAfter: this.slidesOffsetafter,
      spaceBetween: this.spaceBetween,
      // 分页器
      pagination: {
        el: this.$slots.pagination
          ? this.paginationEl()
          : this.$refs.pagination,
      }
    });
  },
};
</script>