<template>
  <div class="pt-95">
    <div class="bg-main h-95 dis-flex a-i-c j-c-b plr-40 app-top">
      <router-link to="home" class="lh-0"
        ><img class="w-65 h-35" src="/static/images/logo.png" alt=""
      /></router-link>
      <div class="dis-flex a-i-c">
        <img
          @click="showNav = !showNav"
          class="w-32 h-26"
          src="/static/images/icon-nav.png"
          alt=""
        />
        <span
          class="fs-24 c-main bg-f fw-b w-46 h-46 dis-flex a-i-c j-c-c br-half ml-60 app-language"
          @click="$app.chageLanguage()"
        >
          {{ $app.curLan.next }}
        </span>
      </div>
      <ul v-if="showNav" class="bg-f c-3b3b3b pd-30 c-383838 app-nav">
        <router-link
          class="h-p100 dis-flex a-i-center"
          :to="{ name: item.content }"
          v-for="(item, index) in $t.homeNav||[]"
          :key="index"
          @click.native="showNav = false"
        >
          <li
            v-if="item.ext1!='1'"
            class="h-75 w-p100 dis-flex fs-30 fw-b a-i-c a-i-center bb-2-f6f6f6"
          >
            {{ item.title }}
          </li>
        </router-link>
      </ul>
    </div>
    <div>
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <router-view />
        </keep-alive>
      </transition>
    </div>
    <div class="h-5 bg-main"></div>
    <div
      class="h-85 fs-24 dis-flex lh-24 flex-column j-c-c a-i-c bg-0 app-bottom"
    >
      <p>
        <img class="w-46 h-25 va-m" src="/static/images/logo.png" />
        <span class="ml-10 c-e0e2e5 va-m ml-5">{{ $g("bottom.title")}}</span>
        <a href="https://beian.miit.gov.cn/" target="_blank" class="ml-10 c-e0e2e5 va-m ml-5">{{ $g("bottom.content")}}</a>
      </p>
      <p class="ml-15">{{ $g("bottom.url") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showNav: false,
      scroll: null
    };
  },
  methods:{
    handleScroll () {
      this.scroll  = document.documentElement && document.documentElement.scrollTop;
    }
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="scss">
.app-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.app-language {
  transform: scale(0.8);
}
.app-nav {
  position: absolute;
  top: 100%;
  left: 22px;
  right: 22px;
  box-shadow: 0px 3px 12px 0px rgba(201, 15, 40, 0.6);
  .router-link-active {
    color: #b02125;
  }
}
.app-bottom {
  color: rgba(255, 255, 255, 0.6);
}
</style>
