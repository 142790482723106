import Vue from 'vue'
import './components'
import router from "./router";
import App from './App.vue'

import "./common/common"
import "./common/common.scss"

// 引入 flexible 用于设置 rem 基准值
import 'amfe-flexible'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
